import React from 'react';
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './LogoCallOut.scss';
import { calculateNumberOfColumns } from '../../utility';

const LogoCallOut = ({ section }) => {
  const numberOfColumns = calculateNumberOfColumns(section?.number_of_columns);

  return (
    <section className='logo-call-out'>
      <Container maxWidth='xl'>
        <Grid container className='logo-call-out__header'>
          <Grid item>
            <h1> {section?.title} </h1>
            <div className='desc'>{section?.description}</div>
          </Grid>
        </Grid>
        <Grid container className='logo-call-out__icons'>
          {section?.icons?.map((icon) => (
            <Grid item md={numberOfColumns} key={icon?.icon?.uid}>
              <div className='logo-call-out__icons-icon'>
                <img src={icon?.icon?.url} alt={icon?.icon_alt_text} />
              </div>
            </Grid>
          ))}

        </Grid>

        {section?.cta?.show_cta &&
          <div className='logo-call-out__cta'>
            <Link to={section?.cta?.url.href}> {section?.cta?.url?.title} {'>'} </Link>
          </div>
        }
        {section?.list_of_brands?.show_list_of_brands &&
          <Grid container className='logo-call-out__brands'>
            {section?.list_of_brands?.brand_name.map((brand) => (
              <Grid item md={calculateNumberOfColumns(section?.list_of_brands?.brands_number_of_columns)} key={brand.name}>
                <div className='logo-call-out__brands-brand'>
                  <h3> {brand} </h3>
                </div>
              </Grid>
            ))}
          </Grid>
        }
      </Container>
    </section>
  );
};

export default LogoCallOut;