import React from 'react';
import './Card.scss';

const Card = ({ item, itemWidth, style, type }) => {
  const cardStyles = itemWidth ? { width: `${itemWidth}` } : style;

  return (
    <div className={`card ${type ? 'carousel__item' : ''}`} style={cardStyles}>
      <div className='card__info'>
        <img src={item?.icon?.url} alt={item?.icon_alt_text} />
        <h4 className='card__title'>{item?.title}</h4>
        <h4 className='card__subtitle'>{item?.subtitle}</h4>
        <p className='card__description'> {item?.description}</p>
      </div>

    </div>
  );
};

export default Card;
