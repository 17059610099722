
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { changeLanguage } from '../../redux/language';
import { callApi } from '../../services/contentstack';
import Breadcrumb from '../common/Breadcrumbs/Breadcrumbs';
import ComponentsContainer from '../ComponentsContainer/ComponentsContainer';
import './L3.scss';

const L3 = () => {
  const [data, setData] = useState();
  const { l3_url, lng } = useParams();
  const { lang } = useSelector(state => state.language);
  const dispatch = useDispatch();

  const setLanguage = () => {
    if (lng) {
      dispatch(changeLanguage(lng.toUpperCase()));
    } else {
      dispatch(changeLanguage(navigator.language.slice(0, 2).toUpperCase()));
    }
  };

  useEffect(() => {
    if (!lang) {
      setLanguage();
    }
  }, []);

  useEffect(() => {

    const loadL3 = async () => {
      const response = await callApi(
        'l3',
        [
          'breadcrumbs.l1_reference',
          'breadcrumbs.l2_reference',
          'components',
          'components.category',
          'components.items',
          'components.items.category',
          'components.articles',
          'components.articles.category',
          'components.articles.applied_tags',
          'components.filtered_articles',
          'components.filtered_articles.articles',
          'components.filtered_articles.articles.category',
          'components.filtered_articles.articles.applied_tags',
          'components.solutions',
          'components.slider_layout.row.row_items',
          'components.slider_layout.row.row_items.category'
        ],
        lang,
        `/${l3_url}`
      );

      setData(response?.data?.entries?.[0]);
    };

    if (lang) {
      loadL3();
    }
  }, [lang]);

  return (
    <div className='l3'>
      {data && (
        <>
          <ComponentsContainer component_info={data?.components[0]} />
          <Breadcrumb
            l1Reference={data.breadcrumbs.l1_reference[0]}
            l2Reference={data.breadcrumbs.l2_reference[0]}
            l3Reference={data}
          />
          {data?.components?.slice(1).map((component) => (
            <ComponentsContainer component_info={component} key={component.uid} />
          ))}
        </>
      )}
    </div>
  );
};

export default L3;
