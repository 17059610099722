import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import './Slider.scss';
import { setBackgroundColor, calculateNumberOfColumns, setBackgroundImage } from '../../utility';

const Slider = ({ slider }) => {
  const { lang } = useSelector(state => state.language);

  const getLOBColor = (sliderItem) => {
    return { backgroundColor: sliderItem?.category?.[0]?.color?.color };
  };

  return (
    <section className='slider' style={setBackgroundColor(slider?.background_color)}>
      <Container maxWidth='xl'>
        <Grid container >
          <div className='slider__info'>
            <h2 className='slider__title'>{slider?.title}</h2>
            <h3 className='slider__description'>{slider?.description}</h3>
          </div>
        </Grid>

        {slider?.slider_layout?.map((element) => (
          <Grid container key={element?.uid} spacing={3} className='slider__row'>
            {element.row?.row_items?.map((item) => (
              <Grid item key={item?.uid} md={calculateNumberOfColumns(element?.row?.row_items?.length)}>
                <div className='slider-item' >
                  <div
                    style={setBackgroundImage(item?.image?.url, 'center', 'cover')}
                    alt={item?.image_alt_text}
                    className='slider-item__image'
                  />
                  <div className='slider-item__info'>
                    {slider?.show_lob && <div className='slider-item__info-lob' style={getLOBColor(item)} />}
                    <h4 className='slider-item__title'>{item?.title}</h4>
                    <p className={`slider-item__description${element?.row?.row_items?.length > 3 ? '-longer' : ''}`}>
                      {item?.description?.substring(0, 300)}
                    </p>
                    <div className='slider-item__link'>
                      {item?.show_url &&
                        <Link to={`/${lang?.toLowerCase()}${item?.url}`} data-testid='itemLink'>
                          {'Learn More'}
                          <i className="fas fa-chevron-right fa-lg" />
                        </Link>}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        ))}
      </Container>
    </section>
  );
};

export default Slider;
