import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import SearchNavigation from '../SearchNavigation/SearchNavigation';
import ArticleCard from '../common/ArticleCard/ArticleCard';
import './FilterSection.scss';
import { setBackgroundColor } from '../../utility';

const FilterSection = ({ element }) => {
  const [filter, setFilter] = useState('');
  const [sortedArticles, setSortedArticles] = useState(element?.filtered_articles?.[0].articles);
  const columns = 12 / element?.filtered_articles?.[0].number_of_columns;

  const handleFilter = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const formatDate = (str) => {
    const date = str.slice(0, 10);
    const time = str.slice(11, 19);

    return new Date(`${date}T${time}`);
  };

  const sortArticles = (newOption) => {
    const articles = sortedArticles;
    if (newOption === 'Date - Newest to Oldest') {
      articles.sort((art1, art2) => (
        formatDate(art2.created_at) - formatDate(art1.created_at)
      ));
    } else {
      articles.reverse();
    }
    setSortedArticles([...articles]);
  };

  useEffect(() => {
    sortArticles('Date - Newest to Oldest');
  }, []);


  return (
    <section className='filter-articles' style={setBackgroundColor(element?.background_color)}>
      <Container maxWidth='xl'>
        <SearchNavigation
          title={element.title}
          solutions={element?.solutions}
          sortBy={element?.sort_by}
          handleFilter={handleFilter}
          sortArticles={sortArticles}
        />

        <Grid container spacing={1}>
          {sortedArticles?.map((article) => {
            return (!filter ?
              <Grid item sm={6} md={columns} >
                <ArticleCard
                  article={article}
                  showTags={element?.filtered_articles?.[0].show_article_tags}
                  key={article?.uid}

                />
              </Grid> :
              (filter === article.category?.[0].title) &&
              <Grid item sm={6} md={columns} >
                <ArticleCard
                  article={article}
                  showTags={element?.filtered_articles?.[0].show_article_tags}
                  key={article?.uid}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </section>
  );
};

export default FilterSection;