export const formatUrl = (url) => {
  let formattedUrl = url;

  if (url.charAt(0) !== '/') {
    formattedUrl = `/${url}`;
  }

  return formattedUrl;
};

export const formtUrlBothAbsolutheAndRelative = (url, lang) => {
  const urlPrefix = 'https://';
  let formattedUrl = url?.startsWith('/') ? url.slice(1) : url;  //in case absoluthe is in relative format

  if (url?.startsWith('https')) { //if it's absoluthe with https
    return url;
  }

  if (url?.startsWith('www')) {
    formattedUrl = url?.slice(4);
  }

  if (url?.startsWith('/')) {  //if it's relative
    return `/${lang?.toLowerCase()}${url}`;
  } else {  //if it's absoluthe without https
    return urlPrefix.concat(formattedUrl);
  }
};

export const setBackgroundColor = (color) => {
  if (color === 'Grey') {
    return { background: '#F1F1F1' };
  }

  if (color === 'White') {
    return { background: '#FFFFFF' };
  }

  return { background: `${color}` };
};

export const calculateNumberOfColumns = (numOfColumns) => {
  return 12 / numOfColumns;
};

export const setBackgroundImage = (url, position, size) => (
  {
    backgroundImage: `url(${url})`,
    backgroundPosition: position,
    backgroundSize: size
  }
);