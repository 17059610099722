import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import './Split.scss';
import { calculateNumberOfColumns, setBackgroundColor } from '../../utility';

const Split = ({ split }) => {
  const { lang } = useSelector(state => state.language);
  const calcColumns = calculateNumberOfColumns(split?.number_of_columns);

  return (
    <section className='split' style={setBackgroundColor(split?.background_color)}>
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          {split?.items.map((item) => (
            <Grid item key={item.uid} md={calcColumns}>
              <div className='split__item' >
                <img src={item?.image?.url} alt={item?.alt_text} />
                <div className='split__item-info'>
                  {split?.show_lob && <div className='split__item-lob' style={{ background: item?.color?.color }} />}
                  <div className='split__item-title'>
                    <h3>{item?.title}</h3>
                    {split?.redirect_align === 'same height as title' && (
                      <Link to={`/${lang?.toLowerCase()}${item.url}`} className='split__item-link' data-testid='itemLink'>
                        Learn more
                      </Link>
                    )}
                  </div>
                  <p>{item?.description}</p>
                  {split?.redirect_align === 'after description' && (
                    <Link to={`/${lang?.toLowerCase()}${item.url}`} className='split__item-link-right' data-testid='itemLink'>
                      {split?.cta_label} {'>'}
                    </Link>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section >
  );
};

export default Split;
