/* eslint-disable */
import React from 'react';
import HeroSplit from '../HeroSplit/HeroSplit';
import ImagesSection from '../ImagesSection/ImageSection';
import ArticlesSection from '../ArticlesSection/ArticlesSection';
import BulletsSection from '../BulletsSection/BulletsSection';
import CardsSection from '../CardsSection/CardsSection';
import HeroMaterialsSection from '../HeroMaterialsSection/HeroMaterialsSection';
import IconsSection from '../IconsSection/IconsSection';
import Slider from '../Slider/Slider';
import Split from '../Split/Split';
import FilterSection from '../FilterSection/FilterSection';
import FeatureListWithBullets from '../FeatureListWithBullets/FeatureListWithBullets';
import FeatureListWithIcons from '../FeatureListWithIcons/FeatureListWithIcons';
import EmptySpace from '../common/EmptySpace/EmptySpace';
import MultiTopicWithScrolller from '../MultiTopicWithScrolller/MultiTopicWithScrolller';
import LogoCallOut from '../LogoCallOut/LogoCallOut';
import ProcessOrderedList from '../ProcessOrderedList/ProcessOrderedList';

const ComponentsContainer = ({ component_info }) => {
  switch (component_info?._content_type_uid) {
    case 'hero_split':
      return (
        <HeroSplit
          element={component_info}
          key={component_info.uid}
        />
      );
    case 'split':
      return (
        <Split
          split={component_info}
          key={component_info.uid}
        />
      );
    case 'section_with_cards':
      return (
        <CardsSection
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'section_with_icons':
      return (
        <IconsSection
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'section_with_articles':
      return (
        <ArticlesSection
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'section_with_bullets':
      return (
        <BulletsSection
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'section_with_hero_materials':
      return (
        <HeroMaterialsSection
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'images_section':
      return (
        <ImagesSection
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'slider':
      return (
        <Slider
          slider={component_info}
          key={component_info.uid}
        />
      );
    case 'filter_section':
      return (
        <FilterSection
          element={component_info}
          key={component_info.uid}
        />
      );
    case 'feature_list_with_bullets':
      return (
        <FeatureListWithBullets
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'feature_list_with_icons':
      return (
        <FeatureListWithIcons
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'empty_space':
      return (
        <EmptySpace
          section={component_info}
          key={component_info.uid}
        />
      );
    case 'multi_topic_content_block_with_scroller':
      return (
        <MultiTopicWithScrolller
          section={component_info}
          key={component_info.uid} />
      );
    case 'logo_call_out':
      return (
        <LogoCallOut
          section={component_info}
          key={component_info.uid} />
      )
    case 'process_ordered_list':
      return (
        <ProcessOrderedList
          section={component_info}
          key={component_info.uid} />
      )
    default:
      return '';
  }

};

export default ComponentsContainer;
