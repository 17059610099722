import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import ArticleCard from '../../ArticleCard/ArticleCard';
import './Dropdown.scss';
import { calculateNumberOfColumns } from '../../../../utility';

const Dropdown = ({ link, closeMenu }) => {
  const [activeCategory, setActiveCategory] = useState();
  const calcColumns = calculateNumberOfColumns(link?.articles_number_of_columns);
  const { lang } = useSelector(state => state.language);

  const handleOnHoverSubItem = (vertical) => {
    setActiveCategory(vertical);
  };

  const handleOnMouseLeaveDropdown = () => {
    setActiveCategory(null);
    closeMenu();
  };

  return (
    <Container maxWidth='xl'>
      <div className='dropdown' onMouseLeave={() => handleOnMouseLeaveDropdown()}>
        <div className='dropdown__categories'>
          <div className='dropdown__categories-lob' >
            <div className='dropdown__categories-lob-inside' />
          </div>
          {link?.vertical_links.map((vertical) => (
            <li
              onMouseEnter={() => handleOnHoverSubItem(vertical)}
              onClick={() => handleOnMouseLeaveDropdown()}
              className={vertical.title === activeCategory?.title && 'active'}
              key={vertical.uid}
            >
              <Link to={`/${lang?.toLowerCase()}${vertical.url}`} >
                {vertical.title}
                {link?.vertical_links && <i className='fa-sharp fa-solid fa-chevron-right' />}
              </Link>
            </li>

          ))}
        </div>

        {
          link?.isgroup && activeCategory ? (
            <>
              {link?.vertical_links?.subcategories &&
                <ul className='dropdown__subcategories'>
                  {link.vertical_links[
                    link.vertical_links.findIndex(
                      (vertical) => vertical.title === activeCategory?.title
                    )
                  ]?.subcategories?.map((subcategory) => (
                    <li key={subcategory.uid}>
                      <Link to={`${activeCategory?.url + subcategory.url}`} >
                        {subcategory.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              }
              <div className='dropdown__article'>
                <Link to={`/${lang?.toLowerCase()}${activeCategory?.url}`} onClick={() => handleOnMouseLeaveDropdown()}>
                  {activeCategory?.link_cta + ' ' + activeCategory?.title}
                </Link>

                {link?.articles?.map(
                  (article) =>
                    article.category[0]?.title === activeCategory?.title && (
                      <div className='dropdown__article-single'>
                        <Container>
                          <Grid container >
                            <Grid item >
                              <ArticleCard article={article} width={40} />
                            </Grid>
                          </Grid>
                        </Container>
                      </div>
                    )
                )}
              </div>
            </>
          ) : (
            <div className='dropdown__articles-full'>
              <Container>
                <Grid container spacing={2}>
                  {link?.articles?.map((article) => (
                    <Grid item lg={calcColumns} key={article?.uid}>
                      <ArticleCard article={article} width={25} key={article.uid} dropdownAction={handleOnMouseLeaveDropdown} />
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </div>
          )
        }
      </div >
    </Container >
  );
};

export default Dropdown;
