import React from 'react';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ l1Reference, l2Reference, l3Reference, l4Reference }) => {
  const { lang } = useSelector(state => state.language);
  if (l2Reference) {
    return (
      <div className='breadcrumbs'>
        <Container maxWidth='xl' data-testid='breadcrumbs'>
          <Link to={`/${lang?.toLowerCase()}`}>{l1Reference?.title}</Link>
          {l2Reference && (
            <>
              /{' '}
              <Link to={`/${lang?.toLowerCase()}${l2Reference?.url}`} className='active' data-testid='breadcrumbLink'>
                {l2Reference?.title}
              </Link>
            </>
          )}
          {l3Reference && (
            <>
              /{' '}
              <Link
                to={`/${lang?.toLowerCase()}${l2Reference?.url}${l3Reference?.url}`}
                className='active'
              >
                {l3Reference?.title}
              </Link>
            </>
          )}
          {l4Reference && (
            <>
              /{' '}
              <Link
                to={`/${lang?.toLowerCase()}${l2Reference.url}${l3Reference?.url}${l4Reference?.url}`}
                className='active'
              >
                {l4Reference?.title}
              </Link>
            </>
          )}
        </Container>
      </div>
    );
  }
};

export default Breadcrumbs;
