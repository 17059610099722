import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './ArticleFullWidth.scss';
import { formatUrl, setBackgroundImage } from '../../../utility';

const ArticleFullWidth = ({ article }) => {
  const { lang } = useSelector(state => state.language);

  const articleInfoStyles = {
    borderBottom: `13px solid ${article?.category[0]?.color}`,
  };

  const lobStyle = { background: article?.category[0]?.color?.color || article?.category[0]?.color };

  return (
    <div className={` article-full-width ${article?.alignment === 'right' && 'right-mobile'}`}>
      {article?.alignment === 'left' && (
        <div className='article-full-width__image' style={setBackgroundImage(article?.image?.url, 'center', 'cover')}>
          <div className='article-full-width__image-lob-left' style={lobStyle} />
        </div>
      )}
      <div className='article-full-width__info' style={articleInfoStyles}>
        <div className={`article-full-width__info-content ${article?.alignment === 'right' && 'right'}`}>
          <h4>{article?.title}</h4>
          <p className='article-full-width__info-description'>
            {article?.preview_text}
          </p>
          <Link to={`/${lang?.toLowerCase()}/articles${formatUrl(article?.cta?.url?.href)}`} className='article-full-width__link' data-testid='articleLink'>
            {article?.cta?.url?.title}
            <i className="fas fa-chevron-right fa-lg" />
          </Link>
        </div>

      </div>
      {
        article?.alignment === 'right' && (
          <div className='article-full-width__image' style={{ backgroundImage: `url(${article?.image?.url})` }}>
            <img src={article?.image?.url} alt='lorem' />
            <div className='article-full-width__image-lob-right' style={lobStyle} />
          </div>
        )
      }

    </div >
  );
};

export default ArticleFullWidth;
