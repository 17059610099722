import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { changeLanguage } from '../../redux/language';
import { callApi } from '../../services/contentstack';
import ComponentsContainer from '../ComponentsContainer/ComponentsContainer';
import './L1.scss';

const L1 = () => {
  const [data, setData] = useState();
  const { lang } = useSelector(state => state.language);
  const { lng } = useParams();
  const dispatch = useDispatch();

  const setLanguage = () => {
    if (lng) {
      dispatch(changeLanguage(lng.toUpperCase()));
    } else {
      dispatch(changeLanguage(navigator.language.slice(0, 2).toUpperCase()));
    }
  };

  useEffect(() => {
    if (!lang) {
      setLanguage();
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const response = await callApi(
        'l1',
        [
          'components',
          'components.items',
          'components.cards',
          'components.articles',
          'components.articles.category',
          'components.slider_layout.row.row_items',
          'components.slider_layout.row.row_items.category'
        ],
        lang
      );
      setData(response?.data?.entries?.[0].components);
    };

    if (lang) {
      loadData();
    }
  }, [lang]);

  return (
    <div data-testid='l1' className='l1'>
      {data && (
        <>
          {data?.map((component) => (
            <ComponentsContainer component_info={component} key={component.uid} />
          ))}
        </>
      )}
    </div>
  );
};

export default L1;
