import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formtUrlBothAbsolutheAndRelative } from '../../../../utility';
import './HamburgerMenu.scss';

const HamburgerMenu = ({
  menuData,
  isMobileMenuOpen,
  setSubMenuItem,
  subMenuItem,
  handlePreRedirectionActions,
  handleLanguageChange
}) => {
  const { lang } = useSelector(state => state.language);
  const navigate = useNavigate();

  const handleMenuItemClick = (subMenuItem) => {
    let subItems = subMenuItem.vertical_links || subMenuItem.subcategories;
    if (!subItems.length) {
      handlePreRedirectionActions();
      if (subMenuItem?.url) {
        navigate(`/${lang?.toLowerCase()}${subMenuItem?.url}`);
      }
    } else {
      setSubMenuItem({ title: subMenuItem.title, subItemsArray: subItems, url: subMenuItem.url });
    }
  };

  const SearchForm = () => (
    <form action=''>
      <input
        type='search'
        name='search'
        placeholder='Discover solutions and insights'
      />
      <button type='submit'>
        <i className='fas fa-search fa-lg'></i>
      </button>
    </form>
  );

  const MenuBottom = () => (
    <div className='mobile__menu-locale'>
      <div className='languages'>
        <span className='language' onClick={() => handleLanguageChange('EN')}>EN</span> /{' '}
        <span className='language selected' onClick={() => handleLanguageChange('FR')}>FR</span>
      </div>
      <div className='mobile__menu-chat'>
        <i className="fa-solid fa-headset"></i>
        <a href={formtUrlBothAbsolutheAndRelative(menuData?.top_header_links?.[0]?.link?.href)}
          target={menuData?.top_header_links?.[0]?.should_open_in_new_tab && '_blank'}>
          {menuData?.top_header_links?.[0]?.link?.title}
        </a>
      </div>
    </div>
  );

  const SubMenu = () => {
    return (
      <div className='mobile__submenu' style={{ display: !isMobileMenuOpen && 'none' }}>
        <div className='mobile__submenu-wrapper'>
          <h2 className='mobile__submenu-h2-top'>
            {subMenuItem.title}

          </h2>
          <div className='mobile__submenu-lob-ul'>
            <ul>
              {subMenuItem.subItemsArray.map((item) => (
                <li className='mobile__link' key={item?.uid} onClick={() => handlePreRedirectionActions()}>
                  {/* <span>{item.title}</span> */}
                  <Link to={`/${lang?.toLowerCase()}${item.url}`}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </div >
          <h2>
            View All {subMenuItem.title}
          </h2>
        </div >
      </div >
    );
  };

  const Menu = () => {
    const categories = menuData && menuData?.links?.find((link) => link.title === 'Solutions')?.vertical_links;

    return (
      <div className='mobile__menu' style={{ display: !isMobileMenuOpen && 'none' }} data-testid='mobileMenu'>
        <div className='mobile__menu-wrapper'>
          <SearchForm />
          <div className='mobile__menu-main'>
            <ul>
              {menuData?.links?.map((link, index) => (
                <li className='mobile__link' key={index} onClick={() => handleMenuItemClick(link)}>
                  {link.islink ? (
                    <Link to={`/${lang?.toLowerCase()}${link.normal_link.href}`}>
                      {link.normal_link.title}
                    </Link>
                  ) : (
                    <>
                      <span>{link.title}</span>
                    </>
                  )}
                  {index !== 0 && <span>
                    <i className='fas fa-chevron-right fa-lg'></i>
                  </span>}
                </li>
              ))}
            </ul>
          </div>

          <div className='mobile__menu-lob'>
            <ul>
              {categories?.map((category) => (
                <li className='mobile__link' key={category?.uid} onClick={() => handleMenuItemClick(category)}>
                  <span>{category.title}</span>
                  <span>
                    <i className='fas fa-chevron-right fa-lg'></i>
                  </span>

                </li>
              ))}
            </ul>
          </div>

          <div className='mobile__menu-other'>
            <a href={formtUrlBothAbsolutheAndRelative(menuData?.top_header_links?.[2]?.link?.href)}
              target={menuData?.top_header_links?.[2]?.should_open_in_new_tab && '_blank'}>
              {menuData?.top_header_links?.[2]?.link?.title}
            </a>
            <a href={formtUrlBothAbsolutheAndRelative(menuData?.top_header_links?.[1]?.link?.href)}
              target={menuData?.top_header_links?.[1]?.should_open_in_new_tab && '_blank'}>
              {menuData?.top_header_links?.[1]?.link?.title}
            </a>
          </div>
          <MenuBottom />
        </div>
      </div >
    );
  };


  return (
    menuData && isMobileMenuOpen && (
      <div className='mobile'>
        {!subMenuItem ?
          <Menu /> :
          <SubMenu />}
      </div>
    )
  );
};


export default HamburgerMenu;