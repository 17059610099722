import React from 'react';
import './IconsSection.scss';

const IconsSection = ({ section }) => {
  const itemWidth = 100 / section?.items?.length - 3;
  const iconsItemStyles = {
    width: `${itemWidth}%`,
  };

  return (
    <section className='icons'>
      <h3 className='icons__title'>{section?.title}</h3>
      <div className='icons__items'>
        {section?.items?.map((item) => (
          <div className='icons__item' style={iconsItemStyles} key={item._metadata.uid}>
            <img
              src={item?.icon?.url}
              alt={
                item?._content_type_uid === 'subcategory'
                  ? item?.alt_text
                  : item?.icon_alt_text
              }
            />
            <h4>{item.title}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default IconsSection;
