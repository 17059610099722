import React from 'react';
import { Container, Grid } from '@mui/material';
import parse from 'html-react-parser';
import './FeatureListWithBullets.scss';
import ClipPath from '../common/ClipPath/ClipPath';
import { setBackgroundColor } from '../../utility';

const FeatureListWithBullets = ({ section }) => {
  const columns = 12 / section?.number_of_columns;

  const printItem = (currentItem) => {
    const parsedItem = parse(currentItem);
    if (parsedItem?.type === 'p') {
      return parsedItem.props.children;
    } else {
      return parsedItem;
    }
  };

  return (
    <section className='feature-list' data-testid='feature-list-with-bullets' style={setBackgroundColor(section?.background_color)}>
      <ClipPath
        direction='left'
        color='#007000'
      />
      <Container maxWidth='xl'>
        <Grid container>
          <h3>{section?.title} </h3>
          <p>{section?.description}</p>
        </Grid>
        <Grid container justifyContent={'space-between'}>
          <ul className='feature-list__bullets'>
            {section?.items?.map((item) => (
              <Grid item key={item?._metadata.uid} md={columns} className='feature-list__bullets-item'>
                <li>
                  <h5 className='feature-list__bullets-title'>{item?.item_title} </h5>
                  <p className='feature-list__bullets-description'>{printItem(item?.item_description)}</p>
                </li>
              </Grid>
            ))}
          </ul>
        </Grid>
      </Container>

    </section >
  );
};

export default FeatureListWithBullets;