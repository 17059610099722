import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { callApi } from '../../../services/contentstack';
import Navigation from '../Navigation/Navigation';
import './Header.scss';
import HamburgerMenu from '../Navigation/HamburgerMenu/HamburgerMenu';
import { changeLanguage } from '../../../redux/language';
import { formtUrlBothAbsolutheAndRelative } from '../../../utility';

const Header = () => {
  const [menuData, setMenuData] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [subMenuItem, setSubMenuItem] = useState(null); //mobile
  const { lang } = useSelector(state => state.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    const loadData = async () => {
      const response = await callApi(
        'header_menu',
        [
          'links.vertical_links',
          'links.articles',
          'links.articles.category',
          'links.vertical_links.subcategories',
        ],
        lang
      );
      setMenuData(response?.data?.entries?.[0]);
    };

    if (lang) {
      loadData();
    }
  }, [lang]);

  //mobile
  const handleGoBack = () => {
    setSubMenuItem(null);
  };

  //mobile
  const handlePreRedirectionActions = () => {
    toggleMobileMenu();
    setSubMenuItem(null);
  };
  //mobile
  const toggleMobileMenu = (newVal) => {
    //in case of closing from "Contuct us"
    if (newVal !== undefined) {
      setIsMobileMenuOpen(newVal);
    } else {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    }
  };

  const handleLanguageChange = (newLanguage) => {
    let newLang;

    if (newLanguage) {
      newLang = newLanguage;
      dispatch(changeLanguage(newLang));
      handlePreRedirectionActions();
    } else {
      if (lang === 'EN') {
        newLang = 'FR';
        dispatch(changeLanguage(newLang));
      } else {
        newLang = 'EN';
        dispatch(changeLanguage(newLang));
      }
    }

    const params = location.href.split(location.origin)[1];
    const existingLang = params.slice(1, 3);
    let url;

    // if params starts  with lang, cut the lang, else just concat
    if (existingLang === 'en' || existingLang === 'fr') {
      url = params.slice(3);
    } else {
      url = params;
    }

    navigate(`/${newLang?.toLowerCase()}${url}`);
  };

  const HeaderDesktop = () => (
    <header className='header'>
      <Container maxWidth='xl' data-testid='header'>
        <div className='logo'>
          <a href={menuData?.logo_url?.href}>
            <img src={menuData?.logo?.url} alt='logo' className='logo__image' />
          </a>
          <div className='top'>
            {menuData?.search_bar?.should_show_search_bar &&
              <form action=''>
                <input
                  type='search'
                  name='search'
                  placeholder={menuData?.search_bar?.placeholder_label}
                />
                <button type='submit'>
                  <i className="fas fa-search fa-lg"></i>
                </button>
              </form>
            }
            <ul>
              {menuData?.top_header_links?.map((link) => (
                <li key={link?.title}>
                  <a
                    href={formtUrlBothAbsolutheAndRelative(link?.link?.href, lang)}
                    target={link?.should_open_in_new_tab && '_blank'}
                  >
                    {link?.link?.title === 'Live Help' && <i className="fa-solid fa-headset"></i>}
                    {link?.link?.title}
                  </a>
                </li>
              ))}
              <li onClick={() => handleLanguageChange()} className='top-lang'> {lang === 'EN' ? 'FR' : 'EN'}</li>
            </ul>
          </div>
        </div>

      </Container>
    </header>
  );

  const HeaderMobile = () => {

    const renderIcon = () => {
      if (isMobileMenuOpen && !subMenuItem) {
        //close
        return <i className='fa-solid fa-times' onClick={() => toggleMobileMenu()} />;
      }
      else if (subMenuItem) {
        //go-back
        return <i className='fa fa-arrow-left' onClick={() => handleGoBack()} />;
      } else {
        //hamburger
        return <i className='fa-solid fa-bars' onClick={() => toggleMobileMenu()} />;
      }
    };

    return (
      <div className='mobile__header' data-testid='header-mobile'>
        <div className='mobile__header-bars'>
          {renderIcon()}
        </div>
        <div className='mobile__header-logo' >
          <a href={menuData?.logo_url?.href}>
            <img src={menuData?.logo?.url} alt="logo" />
          </a>
        </div>
        {subMenuItem ?
          <div className='mobile__header-bars-right'>
            <i className='fa-solid fa-times' onClick={() => handlePreRedirectionActions()} />
          </div> :
          <div className='mobile__header-contact' onClick={() => toggleMobileMenu(false)}>
            <a href={formtUrlBothAbsolutheAndRelative(menuData?.navigation_button?.button_details?.href, lang)} target={menuData?.navigation_button?.should_open_in_new_tab && '_blank'} rel='noreferrer'>
              {menuData?.navigation_button?.button_details?.title}
            </a>
          </div>
        }
      </div >
    );
  };

  return (
    <>
      <HeaderDesktop />
      <Navigation menuData={menuData} />
      <HeaderMobile />
      <HamburgerMenu
        menuData={menuData}
        isMobileMenuOpen={isMobileMenuOpen}
        setSubMenuItem={setSubMenuItem}
        subMenuItem={subMenuItem}
        handlePreRedirectionActions={handlePreRedirectionActions}
        handleLanguageChange={handleLanguageChange}
      />
    </>
  );
};

export default React.memo(Header);
