export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const LangMap = new Map();
LangMap.set('EN', 'en-us');
LangMap.set('FR', 'fr-ca');

export const fontSizeMap = {
  sm: '16px',
  md: '24px',
  lg: '30px',
  xl: '100px'
};
