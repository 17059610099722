import React, { useState } from 'react';
import './SearchNavigation.scss';

const SearchNavigation = ({ title, solutions, sortBy, handleFilter, sortArticles }) => {
  const [isOpenSortDropdown, setIsOpenSortDropdown] = useState(false);
  const [selectedSortingOption, setSelectedSortingOption] = useState('option_1');
  const [selectedFilter, setSelectedFilter] = useState('');

  const lobStyle = (solution) => {
    return solution?.color?.color || solution?.color;
  };

  const toggleDropdown = () => {
    setIsOpenSortDropdown(!isOpenSortDropdown);
  };

  const handleSortBy = (newOption) => {
    setSelectedSortingOption(newOption);
    setIsOpenSortDropdown(false);
    sortArticles(newOption);
  };

  const handleFilterSelection = (filter) => {
    handleFilter(filter);
    setSelectedFilter(filter);
  };

  return (
    <div className='search-navigation'>
      <div className='search-navigation-content'>
        <h2>
          {title}
        </h2>
        <div className='search-navigation-content-filters'>
          {solutions?.map((solution) => (
            <span className={(solution?.title === selectedFilter) ? 'search-navigation-content-filter active' : 'search-navigation-content-filter'}
              key={solution?.uid}
              onClick={() => handleFilterSelection(solution?.title)}
            >
              {solution?.title}
              <div className='search-navigation-content-filter-lob' >
                <div className='search-navigation-content-filter-lob-inside' style={{ background: `${lobStyle(solution)}` }} />
              </div>
            </span>
          ))}
          <span className='search-navigation-content-filter' onClick={() => handleFilterSelection('')}>
            View All
            <div className='search-navigation-content-filter-lob' >
              <div className='search-navigation-content-filter-lob-inside' style={{ background: '#007000' }} />
            </div>
          </span>

          <div className='search-navigation-content-sortBy' >
            <span className='search-navigation-content-sortBy-dropdown'
              onClick={() => toggleDropdown()}
            >
              {sortBy?.option_1}
              <i className="fa-solid fa-chevron-down" />
            </span>
            {isOpenSortDropdown &&
              <ul className='sort-options'>
                {Object.keys(sortBy).map((opt) => {
                  return <li key={opt}
                    className={(opt === selectedSortingOption) && 'active'}
                    onClick={() => handleSortBy(sortBy?.[opt])}
                  >
                    {sortBy?.[opt]}

                  </li>;
                })}
              </ul>
            }
          </div>
        </div>
      </div>
    </div >
  );
};

export default SearchNavigation;