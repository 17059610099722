import React from 'react';
import { Container, Grid } from '@mui/material';
import './ProcessOrderedList.scss';
import { setBackgroundImage } from '../../utility';


const SingleCard = ({ card }) => {
  return (
    <div className='single-card'>
      <div className='single-card__image' style={setBackgroundImage(card?.image?.url, 'center', 'cover')} >
        <div className='single-card__image-lob'></div>
      </div>
      <div className='single-card__info'>
        <h4 className='single-card__info-title'>{card?.card_title}</h4>
        <p className='single-card__info-description'> {card?.card_description}</p>
        <a href={card?.cta?.href} className='single-card__info-link'>
          {card?.cta?.title}
          <i className="fas fa-chevron-right fa-lg" />
        </a>
      </div>
    </div>
  );
};

const ProcessOrderedList = ({ section }) => {
  console.log('ProcessOrderedList:', section);

  return (
    <section className='process-ordered-list'>
      <Container maxWidth='xl'>
        <Grid container>
          <Grid item className md={12}>
            <div className='list-header'>
              <h3 className='list-header__title'>{section?.title} </h3>
              <p className='list-header__description'>{section?.description} </p>
            </div>
          </Grid>
          <div className='list-body'>
            {section?.cards?.map((card) => (
              <SingleCard card={card} key={card?.title} />
            ))}
          </div>

        </Grid>
      </Container>
    </section >
  );
};

export default ProcessOrderedList;