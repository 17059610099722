import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import './Footer.scss';
import NewsletterForm from './NewsletterForm/NewsletterForm';
import { callApi } from '../../../services/contentstack';
import { isMobile } from '../../../constants';
import { formtUrlBothAbsolutheAndRelative } from '../../../utility';

const Footer = ({config}) => {
  const [footer, setFooter] = useState(null);
  const { lang } = useSelector(state => state.language);

  useEffect(() => {

    const loadFooter = async () => {
      const response = await callApi('footer', ['solutions_group.solutions', 'industries_group.industries'], lang);
      setFooter(response?.data?.entries?.[0]);
    };

    if (lang) {
      loadFooter();
    }
  }, [lang]);

  return (
    <section className='footer'>
      <Container data-testid='footer' maxWidth='xl'>
        <div className='footer__top'>
          <div className='footer__logo'>
            <h3>{footer?.title}</h3>
            <h5>
              {footer?.subtitle}
            </h5>
            <a href={footer?.logo_url?.href}>
              <img src={footer?.logo?.url} alt='logo' />
            </a>
          </div>
          <div className='footer__solutions'>
            <ul>
              {isMobile && <div className='social__icons-mobile'>
                {footer?.social_links?.map((social) => (
                  <div className='icon' key={social._metadata.uid}>
                    <div>
                      <a href={social.link.href} target='_blank' rel='noreferrer'>
                        <img src={social.icon?.url} alt={social.link.title} />
                      </a>
                    </div>
                  </div>
                ))}
              </div>}
              {footer?.solutions_group?.solutions?.length > 0 && <h4>{footer?.solutions_group?.solutions_title}</h4>}
              {footer?.solutions_group?.solutions?.map((solution) => (
                <li key={solution.uid}>
                  <Link to={`/${lang?.toLowerCase()}${solution.url}`}>{solution.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          {footer?.industries_group?.industries?.length > 0 &&
            <div className='footer__industries'>
              <h4>{footer?.industries_group?.industries_title}</h4>
              <ul>
                {footer?.industries_group?.industries?.map((industry) => (
                  <li key={industry.uid}>
                    <Link to={`/${lang?.toLowerCase()}${industry.url}`}>{industry.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          }
          {footer?.company_links?.links?.length > 0 &&
            <div className='footer__company'>
              <h4>{footer?.company_links?.company_links_title}</h4>
              <ul>
                {footer?.company_links?.links?.map((singleLink) => (
                  <li key={singleLink._metadata.uid}>
                    <a href={`${formtUrlBothAbsolutheAndRelative(singleLink.link.href, lang)}`} target={singleLink?.should_open_in_new_tab && '_blank'}>{singleLink.link.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          }
        </div>

        <div className='footer__bottom'>
          {!isMobile &&
            <div className='social__icons'>
              {footer?.social_links?.map((social) => (
                <div className='icon' key={social._metadata.uid}>
                  <div>
                    <a href={social.link.href} target={social?.should_open_in_new_tab && '_blank'} rel='noreferrer'>
                      <img src={social.icon?.url} alt={social.link.title} />
                    </a>
                  </div>
                </div>
              ))}
            </div>}

          {footer?.form?.show_form &&
            <div className='footer__newsletter'>
              <NewsletterForm label={footer?.form?.label} placeholder={footer?.form?.input_placeholder} url={footer?.form?.redirection_url} serverID={config.SERVER_ID} />
            </div>
          }
        </div>
      </Container>
    </section>
  );
};

export default Footer;
