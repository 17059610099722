import React from 'react';
import { Container, Grid } from '@mui/material';
import './ContentBlock.scss';
import ClipPath from '../common/ClipPath/ClipPath';
import { fontSizeMap } from '../../constants';
import { formtUrlBothAbsolutheAndRelative } from '../../utility';

const ContentBlock = ({ element }) => {

  const contentBlockLOBStyle = { background: element?.category[0]?.color?.color || '#007000' };

  const HeroSplitInfoWithPadding = () => (
    <Grid item md={5}>
      <div className={`content-block__info-with-padding${element?.align === 'left' ? '-left' : ''}`}>
        {element?.show_lob_upper_corner && (
          <ClipPath
            direction='left'
            color={
              element?.category[0]?.color
                ? element?.category[0]?.color
                : '#007000'
            }
          />
        )}

        <h3 className='content-block__title' style={{ fontSize: fontSizeMap[element?.title_size] }}>{element?.title}</h3>
        <p className='content-block_description' style={{ fontSize: fontSizeMap[element?.description_size] }}>{element?.description}</p>
        {element?.buttons?.map((button) => (
          <a
            href={formtUrlBothAbsolutheAndRelative(button.link.href)}
            className={`content-block__button ${button.issolid && 'solid'}`}
            key={button._metadata.uid}
            role='button'
            target={button?.should_open_in_new_tab && '_blank'}
            rel='noreferrer'
          >
            {button.link.title}
          </a>
        ))}
      </div>
    </Grid>
  );

  const ImageWithPadding = () => (
    <Grid item md={7}>
      <div className='content-block__image-with-padding'>
        <img src={element.image?.url} alt={element?.alt_text} />
        {element?.show_lob && <div className='content-block__image-with-padding-lob' style={contentBlockLOBStyle} />}
      </div>
    </Grid>
  );

  return (
    <section className='content-block'>
      {element?.align === 'left' ? (
        <Container maxWidth='xl'>
          <Grid container alignItems='center' justifyContent='flex-end'>
            <ImageWithPadding />
            <HeroSplitInfoWithPadding />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth='xl'>
          <Grid container alignItems='center' >
            <HeroSplitInfoWithPadding />      {/* need to create separate HeroSplitInfoWith padding and remove all the margins for the info section for that, and leave the margins for big HeroSplit wiothout a padding*/}
            <ImageWithPadding />
          </Grid>
        </Container>
      )
      }
    </section>
  );
};

export default ContentBlock;

