import React from 'react';
import HeroMaterial from '../HeroMaterial/HeroMaterial';
import './HeroMaterialsSection.scss';

const HeroMaterialsSection = ({ section }) => {
  return (
    <section className='hero-materials'>
      {section?.showtitle && (
        <h2 className='hero-materials__title'>{section?.title}</h2>
      )}
      {section?.showdescription && (
        <p className='hero-materials__description'>{section?.description}</p>
      )}
      <div className='hero-materials__items'>
        {section?.items?.map((item) => (
          <HeroMaterial hero={item} key={item.uid} />
        ))}
      </div>
    </section>
  );
};

export default HeroMaterialsSection;
