import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { callApiWithUrlQueryParams } from '../../services/contentstack';
import { Container } from '@mui/material';
import './ContentPage.scss';
import parse from 'html-react-parser';
import HeroSplit from '../HeroSplit/HeroSplit';
import Breadcrumb from '../common/Breadcrumbs/Breadcrumbs';
import { changeLanguage } from '../../redux/language';

const ContentPage = () => {
  const [data, setData] = useState(null);
  const { article_url, lng } = useParams();
  const [searchParams] = useSearchParams();
  const { lang } = useSelector(state => state.language);
  const dispatch = useDispatch();

  const formattedArticleUrl = `${article_url}?cmp=${searchParams.get('cmp')}`;
  const loadData = async () => {
    const response = await callApiWithUrlQueryParams(
      'article',
      ['category', 'subcategory'],
      lang,
      formattedArticleUrl
    );

    setData(response?.data?.entries?.[0]);
  };

  const setLanguage = () => {
    if (lng) {
      dispatch(changeLanguage(lng.toUpperCase()));
    } else {
      dispatch(changeLanguage(navigator.language.slice(0, 2).toUpperCase()));
    }
  };

  //improvement: custom hook
  useEffect(() => {
    if (!lang) {
      setLanguage();
    }
  }, []);


  useEffect(() => {
    if (lang) {
      loadData();
    }
  }, [lang, article_url]);

  const heroSplit = {
    title: data?.title,
    image: data?.image,
    align: 'full-width',
    type: 'headline',
  };

  return (
    <article data-testid='content-page' className='article-content-page'>
      {data?.show_hero_banner &&
        <HeroSplit element={heroSplit} />
      }
      <Breadcrumb
        l1Reference={ lang == 'FR' ? { title: 'Accueil' } : { title: 'Home page' }}
        l2Reference={data?.category[0]}
        l3Reference={data?.subcategory[0]}
        l4Reference={data}
      />
      <Container maxWidth='xl'>
        {data && parse(data.description)}
      </Container>
    </article>
  );
};

export default ContentPage;
