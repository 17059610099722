import React from 'react';
import { Container, Grid } from '@mui/material';
import './HeroSplit.scss';
import ClipPath from '../common/ClipPath/ClipPath';
import { formtUrlBothAbsolutheAndRelative } from '../../utility';
import ContentBlock from '../ContentBlock/ContentBlock';

const HeroSplit = ({ element }) => {
  const backgroundImage = element.show_overlay
    ? `linear-gradient(${element?.overlay_color.color}70, ${element?.overlay_color.color}70), url(${element?.image?.url})`
    : `url(${element?.image?.url})`;

  const HeroSplitHeadlineType = () => {
    const heroSplitHeadlineStyles = {
      backgroundImage: `${backgroundImage}`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    };

    return (
      <div style={heroSplitHeadlineStyles}>
        <div className='hero-split__headline' >
          <h1>{element?.title}</h1>
          <p>{element?.description}</p>
          <div className='hero-split__headline_buttons'>
            {element?.buttons?.map((button) => (
              <a
                href={formtUrlBothAbsolutheAndRelative(button.link.href)}
                className={`hero-split__headline__button ${button.issolid && 'solid'}`}
                key={button._metadata.uid}
                role='button'
                target={button?.should_open_in_new_tab && '_blank'}
                rel='noreferrer'
              >
                {button.link.title}
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const HeroSplitInfo = () => (
    <Grid item md={5}>
      <div className={`hero-split__info${element?.align === 'left' ? '-left' : ''}`}>
        {element?.show_lob_upper_corner && (
          <ClipPath
            direction='left'
            color={
              element?.category[0]?.color
                ? element?.category[0]?.color
                : '#007000'
            }
          />
        )}
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <img src={element.brandlogo?.url} alt={element.brandlogo?.title} />
              <h3 className={`hero-split__title${element?.title_size ? '-big-title' : ''}`}>{element?.title}</h3>
              <p className={`hero-split__description${element?.description_size ? '-big-desc' : ''}`}>{element?.description}</p>
              {element?.buttons?.map((button) => (
                <a
                  href={formtUrlBothAbsolutheAndRelative(button.link.href)}
                  className={`hero-split__button ${button.issolid && 'solid'}`}
                  key={button._metadata.uid}
                  role='button'
                  target={button?.should_open_in_new_tab && '_blank'}
                  rel='noreferrer'
                >
                  {button.link.title}
                </a>

              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </Grid>
  );

  const heroSplitClipPathStyles = { background: element?.category?.[0]?.color?.color || '#007000' };

  const ImageWithoutPadding = () => (
    <Grid item md={7}>
      <div className='hero-split__image'>
        <img src={element.image?.url} alt={element?.alt_text} />
        {element?.show_lob &&
          <div className='clip-path__right' style={heroSplitClipPathStyles} />
        }
      </div>
    </Grid>
  );

  const HeroSplitSectionType = () => {
    const heroSplitStyles =
      element.align === 'full-width' ?
        {
          backgroundImage: `${backgroundImage}`,
          backgroundSize: 'cover',
          color: 'white'
        } : {};

    return (
      element?.should_image_have_padding ? (
        <ContentBlock element={element} />
      ) : (
        <section className='hero-split' style={heroSplitStyles}>
          {element?.alignt === 'left' ? (
            <Container maxWidth={false} disableGutters>
              <Grid container alignItems='center'>
                <ImageWithoutPadding />
                <HeroSplitInfo />
              </Grid>
            </Container>
          ) : (
            <Container maxWidth={false} disableGutters>
              <Grid container alignItems='center'>
                <HeroSplitInfo />
                <ImageWithoutPadding />
              </Grid>
            </Container>
          )}
        </section >
      )
    );
  };

  return (
    element.type === 'headline' ?
      <HeroSplitHeadlineType />
      :
      <HeroSplitSectionType />
  );
};

export default HeroSplit;
