import React from 'react';
import './ImagesSection.scss';

const ImagesSection = ({ section }) => {
  const automatic = 100 / section?.columns - 2;
  const imagesItemStyles = { width: `${automatic}%` };

  return (
    <section className='images'>
      <h3 className='images__title'>{section?.title}</h3>
      <p className='images__description'>{section?.description}</p>
      <div className='images__items'>
        {section?.gallery?.map((image) => (
          <div className='images__item' style={imagesItemStyles} key={image?._metadata?.uid}>
            <img src={image?.url} alt='gallery' />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ImagesSection;
