import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { callApi } from '../../../services/contentstack';
import Dropdown from './Dropdown/Dropdown';
import './Navigation.scss';
import { formtUrlBothAbsolutheAndRelative } from '../../../utility';

const Navigation = (props) => {
  const [menuData, setMenuData] = useState(null);
  const [isDesktopMenuOpen, setDesktopMenuOpen] = useState(false);
  const [hoveredDesktopMenuItem, setHoveredDesktopMenuItem] = useState(null);
  const { lang } = useSelector(state => state.language);

  useEffect(() => {
    const loadMenu = async () => {
      const response = await callApi(
        'header_menu',
        [
          'links.vertical_links',
          'links.articles',
          'links.articles.category',
          'links.vertical_links.subcategories',
        ],
        lang
      );
      setMenuData(response?.data?.entries?.[0]);
    };

    if (lang) {
      loadMenu();
    }
  }, [lang]);

  const openMenu = (hoveredItemValue) => {
    setDesktopMenuOpen(true);
    setHoveredDesktopMenuItem(hoveredItemValue);
  };

  const closeMenu = () => {
    setDesktopMenuOpen(false);
    setHoveredDesktopMenuItem(null);
  };

  return (
    <nav className='navigation'>
      <Container maxWidth='xl' data-testid='navigation' disableGutters>
        <Grid container alignItems='center'>
          <Grid item md={8}>
            <Grid container>
              <ul>
                {(menuData ? menuData : props.menu)?.links?.map((link) => (
                  <li className='nav__item'
                    key={link._metadata.uid}
                    onMouseEnter={() => openMenu(link.title)}
                    onMouseLeave={() => closeMenu()}>
                    {link.islink ? (

                      <Link to={`/${lang?.toLowerCase()}${link.normal_link.href}`}
                        className='nav__link'
                        data-testid='navLink'
                        style={{ background: isDesktopMenuOpen && hoveredDesktopMenuItem === link.title && '#F1F1F1' }}>
                        {link.normal_link.title}
                      </Link>
                    ) : (
                      <>
                        <Link className='nav__link' style={{ background: isDesktopMenuOpen && hoveredDesktopMenuItem === link.title && '#F1F1F1' }}>
                          {link.title}
                          <i className='fa-sharp fa-solid fa-chevron-down' />
                        </Link>

                        {isDesktopMenuOpen && link.isvertical && hoveredDesktopMenuItem === link.title && (
                          <Dropdown link={link}
                            closeMenu={closeMenu}
                          />
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid container justifyContent='flex-end'>
              <div className='buttons'>
                <a href={formtUrlBothAbsolutheAndRelative(menuData?.navigation_button?.button_details?.href)} target={menuData?.navigation_button?.should_open_in_new_tab && '_blank'} rel='noreferrer'>
                  {menuData?.navigation_button?.button_details?.title}
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container >
    </nav >
  );
};

export default Navigation;