import React from 'react';
import { Container, Grid } from '@mui/material';
import { setBackgroundColor } from '../../utility';
import ClipPath from '../common/ClipPath/ClipPath';
import './BulletsSection.scss';

const BulletsSection = ({ section }) => {
  return (
    <section className='bullets' style={setBackgroundColor(section?.background_color)}>
      <Container maxWidth='xl'>
        <Grid container>
          <Grid item md={6}>
            <div className='bullets__info'>
              <h3>{section?.title}</h3>
              <p>{section?.description}</p>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className='bullets__points'>
              {section?.second_column?.map((item) => (
                <div className='item' key={item?._metadata.uid}>
                  <div className='item__icon'>
                    <div>
                      <img src={item?.icon?.url} alt={item?.alt_text} />
                    </div>
                  </div>

                  <div className='item__description'>
                    <h4>{item?.title}</h4>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        <ClipPath direction='right' color={'#007000'} />
      </Container>
    </section>
  );
};

export default BulletsSection;
