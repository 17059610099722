import React, { useState } from 'react';
import './NewsletterForm.scss';

const NewsletterForm = ({ label, placeholder, url, serverID }) => {
  const [email, setEmail] = useState();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className='newsletter'>
      <p>
        {label}
      </p>
      <div className='newsletter__form'>
        <input type='email' name='email' placeholder={placeholder} onChange={(e) => handleEmailChange(e)} className={!email && 'disabled-input '} />
        <a href={email && `${url}${email}`} target='_blank' rel="noreferrer" className={!email && 'disabled-button'}> <i className="fas fa-chevron-right fa-lg"></i></a>
      </div>
      <p className='server'>{serverID}</p>
    </div>
  );
};

export default NewsletterForm;
