import axios from 'axios';
import { LangMap } from '../constants';

/**
   *
   * fetches all the entries from specific content-type
   * @param {* content-type uid} contentTypeUid
   * @param {* reference field name} referenceFieldPath
   * @param {* current language} lang
   * @param {* deeper level path, i.e. /furniture}
   */
export const callApi = async (contentTypeUid, referenceFieldPath, lang, pagePath) => {
  let apiUrl;
  if (process.env.REACT_APP_IS_LOCAL) {
    apiUrl = `${process.env.REACT_APP_URL}/api/cs/v3/content_types/${contentTypeUid}/entries`;
  } else {
    apiUrl = `/api/cs/v3/content_types/${contentTypeUid}/entries`;
  }

  const response = await axios({
    method: 'get',
    url: apiUrl,
    params: {
      include: referenceFieldPath,
      locale: LangMap.get(lang),
      query: { 'url': pagePath },
      include_owner: true,
      environment: process.env.REACT_APP_CONTENTSTACK_ENVIRONMENT
    }
  });

  return response;
};

export const callApiWithUrlQueryParams = async (contentTypeUid, referenceFieldPath, lang, pagePath) => {

  let apiUrl;
  if (process.env.REACT_APP_IS_LOCAL) {
    apiUrl = `${process.env.REACT_APP_URL}/api/cs/v3/content_types/${contentTypeUid}/entries`;
  } else {
    apiUrl = `/api/cs/v3/content_types/${contentTypeUid}/entries`;
  }

  const response = await axios({
    method: 'get',
    url: apiUrl,
    params: {
      include: referenceFieldPath,
      locale: LangMap.get(lang),
      query: { 'cta.url.href': pagePath },
      include_owner: true,
      environment: process.env.REACT_APP_CONTENTSTACK_ENVIRONMENT
    }
  });

  return response;
};


