import React from 'react';
import './ClipPath.scss';

const ClipPath = ({ direction, color, top }) => {
  const clipPathStyles = {
    background: color || '#00A53C',
    top: `${top && top}`,
  };

  return (
    <div className={`clip-path__${direction}`} style={clipPathStyles}></div>
  );
};

export default ClipPath;
