import React from 'react';
import { Container } from '@mui/material';
import { isMobile } from '../../constants';
import ClipPath from '../common/ClipPath/ClipPath';
import Card from '../Card/Card';
import Carousel from '../Carousel/Carousel';
import './CardsSection.scss';
import { setBackgroundColor } from '../../utility';

const CardsSection = ({ section }) => {
  const itemWidth = 100 / section?.number_of_columns - 2;

  return (
    <section className='cards' style={setBackgroundColor(section?.background_color)}>
      <Container maxWidth='xl'>
        <ClipPath direction='left' color='green' />
        <h3 className='cards__title'>{section?.title}</h3>
        <h5 className='cards__description'>{section?.description}</h5>
        {isMobile ? (
          <Carousel items={section?.cards} />
        ) : (
          <div className='cards__items'>
            {section?.cards?.map((item) => (
              <Card item={item} itemWidth={itemWidth + '%'} key={item.uid} />
            ))}
          </div>
        )}
      </Container>
    </section>
  );
};

export default CardsSection;
