import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ContentPage from './components/ContentPage/ContentPage';
import Header from './components/common/Header/Header';
import Footer from './components/common/Footer/Footer';
import L1 from './components/L1/L1';
import L2 from './components/L2/L2';
import L3 from './components/L3/L3';
import { callApi } from './services/contentstack';

function App() {
  const [config, setConfig] = useState(null);
  const [seoData, setSEOData] = useState();
  const { lang } = useSelector(state => state.language);

  
  useEffect(() => {
    fetch('/config/config.json')
      .then((r) => r.json())
      .then((data) => {
        setConfig(data);
      })
      .catch((error) => {
        console.error('Failed to load config:', error);
      });
  }, []);

  useEffect(() => {
    const loadSeoData = async () => {
      if (config) { // Ensure config is loaded
        const response = await callApi('seo_data', [], lang);
        setSEOData(response?.data?.entries?.[0]);
      }
    };

    if (lang && config) {
      loadSeoData();
    }
  }, [lang, config]);

  if (!config) {
    return <div>Loading...</div>;
  }

  return (
    <React.StrictMode data-testid='app'>
      <Helmet>
        <script src={process.env.REACT_APP_ANALYTICS_URI}></script>
        <title> {`${seoData?.title}`} </title>
        <link rel="icon" href={seoData?.favicon?.href} />
      </Helmet>
      <Router>
        <Header />
        <Routes>
          <Route path='/:lng' element={<L1 />} />
          <Route path='/' element={<L1 />} />
          <Route path='/:lng/:l2_url' element={<L2 />} />
          <Route path='/:l2_url' element={<L2 />} />
          <Route path='/:lng/:l2_url/:l3_url' element={<L3 />} />
          <Route path='/:l2_url/:l3_url' element={<L3 />} />
          <Route path='/:lng/articles/:article_url' element={<ContentPage />} />
          <Route path='/articles/:article_url' element={<ContentPage />} />
        </Routes>
        <Footer config={config} />
      </Router>
    </React.StrictMode >
  );
}

export default App;
