import React from 'react';
import Card from '../Card/Card';
import './Carousel.scss';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

export const Carousel = ({ items }) => {
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={250}
      totalSlides={items?.length}
    >
      <Slider>
        {items?.map((item, index) => (
          <Slide key={item?.uid} index={index} style={{ paddingBottom: '8%' }}>
            <Card item={item} type={'carousel'} />
          </Slide>

        ))}
      </Slider>
      <div className='carousel__navigation'>
        <ButtonBack>
          <div className='carousel__button first'>
            <i className='fa-solid fa-play'></i>
          </div>

        </ButtonBack>
        <DotGroup showAsSelectedForCurrentSlideOnly={true}></DotGroup>
        <ButtonNext>
          <div className='carousel__button'>
            <i className='fa-solid fa-play'></i>
          </div>
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
};

export default Carousel;
