import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import ArticleCard from '../common/ArticleCard/ArticleCard';
import ArticleFullWidth from '../common/ArticleFullWidth/ArticleFullWidth';
import './ArticlesSection.scss';
import { setBackgroundColor, calculateNumberOfColumns } from '../../utility';


const ArticlesSection = ({ section }) => {
  const calcColumns = calculateNumberOfColumns(section?.number_of_columns);

  return (
    <section className='articles' style={setBackgroundColor(section?.background_color)}>
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          <Grid item  >
            {section?.showtitle && (
              <div className='articles__header'>
                <h3 className='articles__title'>{section?.title}</h3>
                <p className='articles__description'>{section?.description}</p>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {section?.articles?.map((article) =>
            section?.articles_alignment === 'vertical' ? (
              <Grid item md={calcColumns} >
                <ArticleCard
                  article={article}
                  key={article?.uid}
                />
              </Grid>
            ) : (
              <Grid item md={calcColumns}>
                <ArticleFullWidth
                  article={article}
                  key={article.uid}
                />
              </Grid>
            )
          )}

        </Grid>
        {
          section?.show_link && (
            <div className='articles__link'>
              <Link to={section?.link?.href} data-testid='sectionLink'>{section?.link?.title}</Link>
            </div>
          )
        }
      </Container>
    </section >
  );
};

export default ArticlesSection;
