
import React from 'react';
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './MultiTopicWithScrolller.scss';
import ClipPath from '../common/ClipPath/ClipPath';

const Card = ({ card }) => {
  return (
    <div className='card'>
      <img src={card?.icon?.url}></img>
      <div className='card-info'>
        <h4> {card?.card_title} </h4>
        <p> {card?.card_description} </p>
        <div className='card-cta'>
          <Link to={card?.cta?.href}> {card?.cta?.title} {'>'} </Link>
        </div>
      </div>
    </div>
  );
};

const MultiTopicWithScrolller = ({ section }) => {
  const lobCategoryStyle = { background: section?.category?.[0]?.color?.color };

  return (
    <section className='multi-topic-with-scroller '>
      <Container maxWidth={'xl'}>
        <ClipPath direction='left' color='green' />
        <Grid container>
          <Grid item md={6}>
            <div className='hero'>
              <div className='hero-image'>
                <img src={section?.image?.url}></img>
                <div className='hero-image-lob' style={lobCategoryStyle}></div>
              </div>
              <div className='hero-title'>
                <h2>
                  {section?.title}
                </h2>
              </div>
              <div className='hero-description'>{section?.description}</div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="scroller-cards">
              {section?.cards.map((card) => (
                <Card card={card} key={card?.title} />
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default MultiTopicWithScrolller;