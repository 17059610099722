import React from 'react';
import './HeroMaterial.scss';

const HeroMaterial = ({ hero }) => {
  const heroMaterialStyles = {
    width: `${hero?.width}`,
    gap: `${hero?.gap ? hero.gap : 6}px`,
    alignItems: `${hero?.align}`,
  };
  const heroMaterialImageStyles = {
    borderBottom: `13px solid ${hero?.category[0]?.color}`,
  };
  
  return (
    <div className='hero-material' style={heroMaterialStyles}>
      <div className='hero-material__image'>
        <img
          src={hero?.image?.url}
          alt={hero?.alt_text}
          style={heroMaterialImageStyles}
        />
      </div>
      <div className='hero-material__info'>
        <h3 className='hero-material__title'>{hero?.title}</h3>
        <p className='hero-material__description'>{hero?.description}</p>
      </div>
    </div>
  );
};

export default HeroMaterial;
