import React from 'react';
import { Container, Grid } from '@mui/material';
import parse from 'html-react-parser';
import './FeatureListWithIcons.scss';
import { setBackgroundColor } from '../../utility';

const FeatureListWithIcons = ({ section }) => {
  const columns = 12 / section?.number_of_columns;

  const printItem = (currentItem) => {
    const parsedItem = parse(currentItem);

    return parsedItem;
  };

  return (
    <section className='feature-list-icons' data-testid='feature-list-with-icons' style={setBackgroundColor(section?.background_color)}>
      <Container maxWidth='xl'>
        <Grid container className='feature-list-icons-inner-container'>
          <Container maxWidth='lg' >
            <Grid container>
              <h3>{section?.title} </h3>
            </Grid>
            <Grid container>
              <p>{section?.description}</p>
            </Grid>
            <Grid container justifyContent={'space-between'}>
              {section?.items?.map((item) => (
                <Grid item key={item?._metadata.uid} md={columns} xs={6}>
                  <div className='feature-list-icons__item'>
                    <img src={item?.icon?.url} alt={item?.alt_text} className='feature-list-icons__icon' />
                    <div className='feature-list-icons__text'>
                      <h5 className='feature-list-icons__title'>{item?.item_title} </h5>
                      <span className='feature-list-icons__description'>{printItem(item?.item_description)}</span>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Grid>
      </Container>
    </section >
  );
};

export default FeatureListWithIcons;