import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './ArticleCard.scss';
import { formatUrl, setBackgroundImage } from '../../../utility';

const ArticleCard = ({ article, showTags, dropdownAction }) => {
  const { lang } = useSelector(state => state.language);

  const printArticle = (currentArticle) => {
    const shorterDesc = currentArticle?.preview_text?.substring(0, 100);
    const formatDesc = shorterDesc?.substring(0, shorterDesc?.lastIndexOf(' ')).concat('...');

    return formatDesc;
  };

  const formtPDFUrl = (url) => {
    const urlPrefix = 'https://';
    const formattedUrl = url.startsWith('/') ? url.slice(1) : url;

    if (url.startsWith(urlPrefix)) {
      return url;
    } else {
      return urlPrefix.concat(formattedUrl);
    }
  };

  const lobStyle = { background: article?.category[0]?.color?.color || article?.category[0]?.color };

  return (
    <article className='article' >
      <div
        style={setBackgroundImage(article?.image?.url, 'center', 'cover')}
        alt={article?.image_alt_text}
        className='article__image'
      />

      <div className='article__info'>
        <div className='article__lob' style={lobStyle}></div>
        <h4>{article?.title}</h4>
        <div className='article__info-text'>
          <p>
            {printArticle(article)}
          </p>
        </div>
        <div className='article__info-cta'>
          {article?.cta && article?.cta?.is_pdf &&
            <a href={formtPDFUrl(article?.cta?.url?.href)} target='_blank' rel="noreferrer" className='article__link' data-testid='articleLink'>
              {article?.cta?.url?.title}
              <i className="fas fa-chevron-right fa-lg" />
            </a>
          }
          {article?.cta && !article?.cta?.is_pdf &&
            <Link
              to={`/${lang?.toLowerCase()}/articles${formatUrl(article?.cta?.url?.href)}`}
              className='article__link'
              data-testid='articleLink'
              onClick={() => dropdownAction && dropdownAction()}
            >
              {article?.cta?.url?.title}
              <i className="fas fa-chevron-right fa-lg" />
            </Link>
          }
        </div>
        {showTags && <div className='article__info-tags'>
          {article?.applied_tags?.map((tag, index) => (
            index < 6 ?
              <div key={tag?.uid + index} className='tag'>
                {tag.title}
              </div> :
              <span className='dots'>
                ...
              </span>
          ))}
        </div>}
      </div>
    </article >
  );
};

export default ArticleCard;
