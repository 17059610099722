import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    lang: null
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.lang = action.payload;
    }
  }
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;